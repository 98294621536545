import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks';
import { CreateUserModalStyled } from '../CreateUserModal/CreateUserModal.styled';
import { toast } from 'react-toastify';

const CreateAdministratorModal = ({ show, onClose }) => {
  const { t } = useTranslation();
  const { createAdministrator } = useAuth();
  const [formData, setFormData] = useState({
    email: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    // Email validation
    if (!formData.email) {
      newErrors.email = t('emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('invalidEmail');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      await createAdministrator(formData);
      toast.success(t('administratorCreatedSuccessfully'));
      setFormData({ email: '' });
      onClose();
    } catch (error) {
      toast.error(t('errorCreatingAdministrator'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      show={show} 
      onHide={onClose} 
      centered 
      dialogAs={CreateUserModalStyled}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('addAdministrator')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{t('email')}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button 
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600" 
          onClick={onClose}
        >
          {t('cancel')}
        </button>
        <button 
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? t('creating') : t('create')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateAdministratorModal; 
import styled from "styled-components";
import ModalDialog from "react-bootstrap/ModalDialog";

export const CreateUserModalStyled = styled(ModalDialog)`
  .modal-content {
    font-family: 'Montserrat';
    background-color: #111E12;
    color: white;
  }

  .modal-dialog {
    max-width: 500px;
    width: 100%;
  }

  .modal-header {
    background-color: #111E12;
    color: white;
    border-bottom: 1px solid #2A3C29;
  }

  .modal-footer {
    border-top: 1px solid #2A3C29;
  }

  .close {
    color: white;
  }

  label {
    font-weight: 500;
    color: #ABCDDF;
  }

  .form-control {
    background-color: #192619;
    border: 1px solid #2A3C29;
    color: white;

    &:focus {
      background-color: #192619;
      border-color: #E8FC8B;
      box-shadow: 0 0 0 0.2rem rgba(232, 252, 139, 0.25);
      color: white;
    }
  }

  select.form-control {
    option {
      background-color: #111E12;
      color: white;
    }
  }

  .form-check-input {
    background-color: #192619;
    border: 1px solid #2A3C29;

    &:checked {
      background-color: #E8FC8B;
      border-color: #E8FC8B;
    }
  }

  .form-check-label {
    color: #ABCDDF;
  }

  .invalid-feedback {
    color: #ff6b6b;
  }
`; 